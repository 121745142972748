import styles from "./Spinner.module.css";

export default function Spinner() {
  return (
    <div
      className={`d-flex justify-content-center align-items-center ${styles.spinnerContainer}`}
    >
      <div className={`spinner-grow ${styles.spinner}`} role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
