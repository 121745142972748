import Api, { ApisByCategory } from "models/Api";

export const ALL_CATEGORY: string = "All";

type FilterProps = {
  query: string;
};

export const filterApis = (apis: Api[], { query }: FilterProps): Api[] => {
  if (!query) {
    return apis;
  }

  return apis.filter((api) =>
    api.name.toLowerCase().includes(query.toLowerCase())
  );
};

export const groupByCategory = (apis: Api[]): ApisByCategory =>
  apis.reduce(function (acc: any, curr: Api) {
    acc[curr.category] = acc[curr.category] || [];
    acc[curr.category].push(curr);
    return acc;
  }, {});

export const getCategories = (apis: Api[]): string[] => {
  const apiCategories: string[] = apis.map((api) => api.category);

  return [...new Set([ALL_CATEGORY, ...apiCategories])];
};

export const sortAlphabetically = (arr: Api[], key: string): Api[] =>
  arr.sort((a, b) => a[key].localeCompare(b[key]));
