import { useEffect, useState } from "react";
import { useRouter } from "next/router";

import {
  filterApis,
  groupByCategory,
  getCategories,
  ALL_CATEGORY,
  sortAlphabetically,
} from "utils/filters";
import Api, { ApisByCategory } from "models/Api";
import ApiCard from "components/ApiCard";

export default function ApiList({ apis }: { apis: Api[] }) {
  const router = useRouter();

  const { pathname, query } = router;

  const [searchQuery, setSearchQuery] = useState<string>(
    `${query?.query || ""}`
  );

  const [category, setCategory] = useState<string>(
    `${query?.category || ALL_CATEGORY}`
  );

  const [filteredApis, setFilteredApis] = useState<Api[]>(apis);

  useEffect(() => {
    const result = apis
      ? filterApis(apis, {
          query: searchQuery,
        })
      : [];

    setFilteredApis(result);
  }, [apis, searchQuery]);

  const onQueryChange = (event: any) => {
    setSearchQuery(event.target.value);

    router.push({
      pathname: pathname,
      query: {
        ...query,
        query: event.target.value,
      },
    });
  };

  const onCategoryChange = (event: any) => {
    setCategory(event.target.value);

    router.push({
      pathname: pathname,
      query: {
        ...query,
        category: event.target.value,
      },
    });
  };

  const categories: string[] = getCategories(apis);

  const filteredApisByCategory: ApisByCategory = groupByCategory(filteredApis);

  return (
    <div>
      <div className="row">
        <div className="col-9">
          <input
            type="search"
            className="form-control mb-4 shadow-sm"
            placeholder="Search APIs by title"
            onChange={onQueryChange}
            defaultValue={searchQuery}
          />
        </div>
        <div className="col-3">
          <select
            className="form-select shadow-sm"
            onChange={onCategoryChange}
            value={category}
          >
            {categories.map((name, index) => (
              <option key={index} value={name}>
                {name}
              </option>
            ))}
          </select>
        </div>
      </div>
      {filteredApis.length ? (
        <div className="row">
          {Object.entries(filteredApisByCategory).map(
            ([categoryName, apis]: [string, Api[]]) => {
              const shouldShowCategory: boolean =
                category === categoryName || category === ALL_CATEGORY;
              const sortedApis: Api[] = sortAlphabetically(apis, "name");

              return (
                shouldShowCategory && (
                  <div key={categoryName} className="mt-1 mb-3">
                    <h5>{categoryName}</h5>
                    <hr />
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
                      {sortedApis.map((api: Api) => {
                        return (
                          <div key={api.id} className="col mb-3">
                            <ApiCard api={api} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )
              );
            }
          )}
        </div>
      ) : (
        <div className="alert alert-info">
          No APIs found.
          {searchQuery && " Please enter another search term."}
        </div>
      )}
    </div>
  );
}
