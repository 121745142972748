import useSWR from "swr";
import { useSession, getSession } from "next-auth/client";
import { useRouter } from "next/router";
import { GetServerSideProps } from "next";

import Spinner from "components/Spinner";
import ApiList from "components/ApiList";

export default function Home() {
  const [session, loading] = useSession();
  const router = useRouter();

  const { data: apis } = useSWR(session ? "/api/apis" : null);

  if (loading) {
    return <Spinner />;
  }

  if (!session) {
    router.push("/login");
    return <Spinner />;
  }

  if (!apis) {
    return <Spinner />;
  }

  return (
    <div>
      <ApiList apis={apis} />
    </div>
  );
}

export async function getServerSideProps(context: GetServerSideProps) {
  const session = await getSession(context);
  return {
    props: {
      session: session,
    },
  };
}
