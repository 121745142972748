import Link from "next/link";

import Api from "models/Api";
import Icon from "components/Icon";

import styles from "./ApiCard.module.css";

type ApiCardProps = {
  api: Api;
};

export default function ApiCard({ api }: ApiCardProps) {
  return (
    <Link href={`/apis/${api.id}`}>
      <div className={`card shadow-sm ${styles.card}`} role="button">
        <div className="card-header d-flex align-items-center justify-content-between">
          <span className="fw-bold text-truncate" title={api.name}>
            {api.name}
          </span>
          {api.visibility === "internal" && (
            <span title="Internal">
              <Icon name="private" size={18} className={`${styles.icon}`} />
            </span>
          )}
        </div>
        <div className="card-body">
          <div className={`${styles.description}`}>
            <small>{api.description}</small>
          </div>
        </div>
      </div>
    </Link>
  );
}
